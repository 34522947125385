import React from 'react'
import Layout from '../layouts/product'

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>We can not find this page. Try going back to the store and finding somethign else.</p>
  </Layout>
)

export default NotFoundPage
